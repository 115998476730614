<template>
  <div class="box">
    <div class="headline">抽题统计</div>
    <div class="content">
      <p>进入抽题统计界面，【系统分析】→【抽题统计】，如下图</p>
      <img src="../../assets/specificationImgs/statistics1.png" width="100%" />
      <p>
        该界面是某个公司，某个部门，某个岗位下的题库被抽取的次数，以及概率的统计信息，界面可清楚的看到该岗位下所有的题库信息，以及它们所被抽取的次数和概率。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>